@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

* {
  /* font-family: "Inter", sans-serif; */
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  /* font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
}

body {
  background-color: #f8fafe;
}

.fw-600 {
  font-weight: 600 !important;
}

.f-64 {
  font-size: 64px;
  font-weight: 500;
  line-height: 77px;
  letter-spacing: -1.9199999570846558px;
  color: #0e1b3c;
}

.f-64 span {
  color: #185ce3;
}

.f-56 {
  font-size: 56px;
  font-weight: 500;
  line-height: 70px;
  letter-spacing: -1.6799999475479126px;
  color: #0e1b3c;
}

.f-18 {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  color: #0e1b3c;
}

.f-15 {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: #525d7a;
}

.f-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #525d7a;
}

.f-40 {
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -1.2000000476837158px;
  color: #0e1b3c;
}

.f-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #6e768a;
}

.common-btn {
  background: #185ce3;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

/* Media Responsiveness Css */
@media (max-width: 991.98px) {
  .f-64 {
    font-size: 36px;
    line-height: 44px;
  }

  .f-40 {
    font-size: 24px;
    line-height: 36px;
  }

  .f-56 {
    font-size: 36px;
    line-height: 44 px;
  }
}
