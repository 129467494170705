.balance-card {
  /* box-shadow: 1.4718068838119507px 2.2077105045318604px 7.359034538269043px 0px #00000026; */
  background: url(../../images/blueCard.png) no-repeat center center;
  background-size: cover;
  box-shadow: 0px 35px 20px -20px rgba(0, 0, 0, 0.45);
  border-radius: 9px;
  padding: 30px;
  min-height: 150px;
  max-width: 100%;
}

.balance-card .token-btn {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-color: #fff;
}