.red-token {
  /* box-shadow: 1.4718068838119507px 2.2077105045318604px 7.359034538269043px 0px
    #00000026; */
  background: url(../../images/blueCard.png) no-repeat center center;
  background-size: cover;
  border-radius: 9px;
  padding: 20px;
  box-shadow: 0px 35px 20px -20px rgba(0, 0, 0, 0.45);
  min-height: 220px;
}

.red-token .token-btn {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-color: #fff;
}
.scard-value{
font-size: 18px;
}