body {
  /* font-family: "Poppins", sans-serif; */
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: rgba(77, 77, 77, 1);
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none !important;
  color: rgba(0, 82, 255, 1);
}

img {
  max-width: 100%;
}

p:last-child {
  margin-bottom: 0;
}

.t-gray {
  color: #787878 !important;
}

ol,
ul {
  list-style: none !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

.offcanvas.offcanvas-end {
  border: 0 !important;
}

input:focus,
select,
textarea {
  box-shadow: none !important;
  outline: none !important;
}

::-webkit-input-placeholder,
.text-black {
  /* Chrome/Opera/Safari */
  color: #000 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #000 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #000 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.text-green {
  color: #1e9f14 !important;
}

.mh-100 {
  min-height: 100vh;
}

.auth-bg {
  background: rgba(223, 241, 239, 1);
  padding-bottom: 60px;
  padding-top: 10px;
}

.signup-card {
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  max-width: 100%;
  width: 500px;
  margin: auto;
  /* max-width: calc(100% - 30px); */
}

.layout-modal .modal-dialog {
  display: flex;
  justify-content: center;
}

.layout-modal .modal-content {
  width: 120%;
}

.signup-inner {
  max-width: 100%;
  margin: auto;
  width: 400px;
  padding: 50px 16px;
}

.basicInfo-card {
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  max-width: 100%;
  width: 800px;
  margin: auto;
  /* max-width: calc(100% - 30px); */
}

.basicInfo-inner {
  max-width: 100%;
  margin: auto;
  width: 700px;
  padding: 20px 16px;
}

.auth-card {
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  max-width: 100%;
  width: 555px;
  margin: auto;
  max-width: calc(100% - 30px);
}

.auth-inner {
  max-width: 100%;
  margin: auto;
  width: 500px;
  padding: 50px 16px;
}

.fs-37 {
  font-size: 37px;
  font-weight: 700;
  line-height: 43px;
  color: rgba(0, 0, 0, 1);
}

.fs-32 {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: rgba(0, 0, 0, 1);
}

.f-14,
.form-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);
}

.f-15 {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.011em;
}

.fs-20 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #000;
}

.fs-12 {
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
}

.fs-24 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.fs-9 {
  font-size: 9px;
  font-weight: 400;
  line-height: 19px;
}

.f-24 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.form-control,
.form-select {
  background: transparent;
  border: 1px solid rgba(196, 196, 196, 1);
  border-radius: 12px;
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: 54px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

input:-internal-autofill-selected {
  background: transparent !important;
}

.form-label {
  color: rgba(0, 0, 0, 0.872);
}

.form-control {
  background: transparent;
  border: 1px solid rgba(196, 196, 196, 1);
  border-radius: 12px;
  color: rgba(0, 0, 0, 1);
  width: 100%;
  height: 44px;
  /* height: 54px; */
  font-size: 12px;
  font-weight: unset;
  /* font-weight: 600; */
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  cursor: default;
}

.input-icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
}

.inputStyle {
  width: 10% !important;
  height: 40px;
  border-radius: 7px;
  border: 1px solid gray;
  margin-left: 8px;
  margin-right: 8px;
  /* background: #dddddd; */
  font-size: 20px;
}

.otp-input-style {
  justify-content: center;
}

.input-inner input {
  padding-left: 50px;
}

.input-icon {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 13px;
  font-size: 24px;
  color: #000;
  opacity: 0.2;
}

.country-field .react-tel-input .flag-dropdown,
.country-field .react-tel-input .form-control {
  height: 50px;
}

.country-field .react-tel-input .form-control {
  border-radius: 12px;
  width: 100%;
  padding-left: 70px;
}

.country-field .react-tel-input .flag-dropdown,
.country-field .react-tel-input .flag-dropdown.open {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  width: 50px;
}

.country-field .react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:hover,
.country-field .react-tel-input .selected-flag:focus {
  background: #fff;
  border-radius: 12px 0 0 12px;
}

.country-field .react-tel-input .selected-flag {
  width: 50px;
}

.pwd input {
  padding: 5px 30px 5px 10px;
  font-size: 16px;
}

.scard-title {
  font-size: 18px;
}

.scard-title1 {
  font-size: 15px;
}

.scard-sub-title {
  font-size: 16px;
}

.scard-btext {
  font-size: 12px;
}

.referral-box {
  align-items: center;
  justify-content: space-between;
  display: flex;
  border: 1px solid #cecece;
  padding: 10px;
  border-radius: 8px;
}

.rws-icon {
  width: 60% !important;
}

.pwd img {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  cursor: pointer;
  position: absolute;
  width: 16px;
  right: 8px;
}

.common-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  border: 0;
  border-radius: 50px;
  background: rgba(0, 82, 255, 1);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.011em;
  width: 100%;
}

.mcard-content span {
  font-size: 14px;
}

.sign-in .pwd input {
  padding-left: 50px;
}

.dashboard-left {
  max-width: 100%;
  width: 280px;
  min-height: 100vh;
  border-right: 1px solid #dbdbdb;
  padding: 0;
}

.dashboard-right {
  width: calc(100% - 280px);
}

.dash-logo {
  padding: 0 0 40px 50px;
  display: block;
}

.dash-pages li {
  margin-bottom: 10px;
}

.active {
  background: linear-gradient(90deg,
      rgba(0, 92, 238, 0.185) 0%,
      rgba(0, 92, 238, 0.2) 100%);
}

/* .pending {
  background-color: #fff;
} */
.dash-pages li a {
  padding: 16px 0 16px 50px;
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.view-btn svg {
  fill: #94a0b4;
  font-size: 34px;
}

.center-height {
  min-height: calc(100vh - 126px);
}

.f-12 {
  /* font-family: "Roboto", sans-serif; */
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  color: #7b7b7b;
}

.pending {
  color: #f9aa4a !important;
}

.declined {
  color: #ff0000 !important;
}

.completed {
  color: #53a451 !important;
}

.left-sidebar {
  position: sticky;
  top: 0;
  padding: 50px 0 0 0;
}

.transaction-data h6 {
  font-size: 14px;
  color: #000;
}

.transaction-data p {
  font-size: 12px;
}

.transaction-data td {
  padding: 20px 0;
}

.transaction-data tr:not(:last-child) td {
  border-bottom: 1px solid #ddd;
}

.possessions-tabs .nav-link {
  border: 0;
  width: 150px;
  text-align: center;
  color: #767676;
  background: #efefef;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  border-radius: 0;
}

.possessions-tabs .nav-link.active {
  background: #005cee;
  color: #fff;
}

#possessions_tabs-tab-all {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

#possessions_tabs-tab-possessions {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.marketplace-tabs .nav-link {
  border: 0;
  width: 150px;
  text-align: center;
  color: #767676;
  background: #efefef;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  border-radius: 0;
}

.marketplace-tabs .nav-link.active {
  background: #005cee;
  color: #fff;
}

#marketplace_tabs-tab-usermarket {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

#marketplace_tabs-tab-solosmarket {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

#marketplace_tabs-tab-USER {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

#marketplace_tabs-tab-SOLOS {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

#send_tabs-tab-ALL {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

#send_tabs-tab-CONTACTS {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

#sr_tabs-tab-SOLOS {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

#sr_tabs-tab-REWARD {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

#sendred_tabs-tab-platform {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

#sendred_tabs-tab-outsideplatform {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

#sendusdc_tabs-tab-platform {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

#sendusdc_tabs-tab-outsideplatform {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.property-block img {
  width: 100%;
}

.pro-details {
  width: 100%;
  bottom: 0;
  padding: 16px;
  left: 0;
}

.border {
  box-shadow: -4px 11px 44px -14px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: -4px 11px 44px -14px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: -4px 11px 44px -14px rgba(0, 0, 0, 0.46);
}

.pro-details button {
  background: #ffa800d9;
  width: 49%;
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  border: 0;
  text-align: center;
  color: #000000;
  border-radius: 6px;
  padding: 10px;
}

.pro-details button svg {
  font-size: 20px;
}

.posse-details .fs-20,
.posse-details p,
.posse-details h5 {
  color: #393c47;
}

.pdf-btn {
  background: #e7e7e7;
  /* font-family: Poppins; */
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 100%;
  width: 357px;
  border: 0;
  padding: 8px 16px;
  border-radius: 4px;
}

.PhoneInputInput {
  background: transparent;
  border: none;
}

.pay-btn {
  position: relative;
  border: 1px solid transparent;
  border-radius: 50px;
  background: #0052ff;
  background-clip: padding-box;
  padding: 6px;
  width: 227px;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}

.pay-btn:hover {
  background: #0052ff;
  color: #ffffff;
}

/* .pay-btn::after {
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: linear-gradient(73.64deg, #4e59fe57 -7.12%, #1ad3fe 109.56%),
    linear-gradient(
      73.64deg,
      rgba(78, 89, 254, 0.2) -7.12%,
      rgba(26, 211, 254, 0.2) 109.56%
    );
  content: "";
  z-index: -1;
  border-radius: 50px;
} */

.user-icon {
  cursor: pointer;
}

/* Setting Page Css */
.edit-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  color: #0052ff;
  cursor: pointer;
}

.user-form input {
  font-size: 12px;
  padding-right: 20px;
  width: 100%;
  height: 44px;
  border-radius: 11px;
  padding-left: 10px;
}

.sellNFT-form input,
.sellNFT-form select,
.sellNFT-form textarea {
  padding-right: 40px;
  width: 100%;
  height: 40px;
  border-radius: 11px;
  padding-left: 10px;
}

input::placeholder {
  opacity: 0.4;
}

.pro-desc::placeholder {
  opacity: 0.4;
}

.form-ctl {
  border: 1px solid #c4c4c4;
  color: rgb(0, 0, 0);
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

.css-qbdosj-Input {
  padding: 0px !important;
  margin: -4px !important;
}

.country-select .css-13cymwt-control {
  border-radius: 10px !important;
}

.country-select .css-13cymwt-control {
  border-radius: 10px !important;
}

.css-19bb58m {
  padding: 0px !important;
  margin: -4px !important;
}

.pro-desc {
  font-size: 12px;
  width: 100%;
  border-radius: 11px;
  padding-block: 11px;
  padding-left: 10px;
}

.pointer {
  cursor: pointer;
}

.toggleSwitch {
  display: inline-block;
  width: 64px;
  height: 30px;
  position: relative;
  overflow: visible;
  padding: 0;
  margin-left: 50px;
  cursor: pointer;
  vertical-align: middle;
}

.toggleSwitch label,
.toggleSwitch>span {
  line-height: 20px;
  height: 20px;
  vertical-align: middle;
}

.toggleSwitch input:focus~a,
.toggleSwitch input:focus+label {
  outline: none;
}

.toggleSwitch label {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
}

.toggleSwitch input {
  position: absolute;
  opacity: 0;
  z-index: 5;
}

.toggleSwitch>span {
  position: absolute;
  left: -50px;
  width: 100%;
  margin: 0;
  padding-right: 50px;
  text-align: left;
  white-space: nowrap;
}

.toggleSwitch>span span {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  width: 50%;
  margin-left: 50px;
  text-align: left;
  font-size: 14px;
  width: 100%;
  left: 10%;
  top: -1px;
  font-weight: 500;
  opacity: 0;
}

.toggleSwitch a {
  position: absolute;
  right: 50%;
  z-index: 4;
  display: block;
  padding: 0;
  left: 3px;
  top: 0.42px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.toggleSwitch>span span:first-of-type {
  font-weight: 500;
  color: #fff;
  opacity: 1;
  left: 50%;
}

.toggleSwitch>span:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50px;
  top: -2px;
  background: #a7a7a7;

  border: 1px solid #ccc;
  border-radius: 30px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.toggleSwitch input:checked~a {
  border-color: #fff;
  left: 100%;
  margin-left: -8px;
}

.toggleSwitch input:checked~span:before {
  border-color: #0097d1;
  box-shadow: inset 0 0 0 30px #0097d1;
}

.toggleSwitch input:checked~span span:first-of-type {
  opacity: 0;
}

.toggleSwitch input:checked~span span:last-of-type {
  opacity: 1;
  color: #fff;
}

.toggleSwitch.large a {
  width: 23px;
  height: 23px;
}

.toggleSwitch.large>span {
  height: 29px;
  line-height: 28px;
}

.toggleSwitch.large input:checked~a {
  left: 70%;
}

.toggleSwitch.large>span span {
  font-size: 14px;
}

.security {
  width: 250px;
}

.error-text {
  font-size: 12px;
}

.logout-btn {
  background: #e80000;
}

.error-msg {
  font-size: 10px;
  /* position: absolute;
  bottom: -22px;
  left: 0; */
}

.forgot-text {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.text-theme {
  color: #0052ff !important;
}

.file {
  position: relative;
  overflow: hidden;
}

.fileinput {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

.abc {
  height: 30px;
  width: 30%;
  border: 1px solid red;
}

.upload {
  max-width: 40rem;
  margin: 2rem auto;
}

/* upload file css */

.uploadImageContainer {
  width: 90%;
  /* height: 150px; */
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.uploadImageContainer:hover .image {
  opacity: 0.3;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 5px;
}

.uploadImageContainer .overlay {
  display: flex;
  column-gap: 5px;
  position: absolute;
  opacity: 0;
  top: 10%;
  left: 25%;
  transition: 0.5s ease;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.uploadImageContainer:hover .overlay {
  opacity: 1;
}

.overlayBtn {
  font-size: x-large;
  cursor: pointer;
}

.tab-content>.active {
  background: transparent;
}

.mf-section p {
  display: none !important;
  margin-bottom: 10px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.smallSidebar .dashboard-left {
  width: 80px;
}

.smallSidebar .dash-pages li a {
  padding: 16px;
  text-align: center;
}

.smallSidebar .dash-pages li a span {
  display: none;
}

.smallSidebar .dash-pages li a i {
  margin: 0 !important;
}

.smallSidebar .dashboard-right {
  width: calc(100% - 80px);
}

.smallSidebar .dash-logo {
  padding: 0 0 40px;
}

.small-icon,
.smallSidebar .big-logo {
  display: none;
}

.smallSidebar .small-icon {
  display: block;
  width: 40px;
  margin: auto;
}

.file.btn.btn-lg {
  padding: 0;
  min-width: none !important;
}

.file.btn.btn-lg label {
  min-width: auto !important;
  height: auto !important;
}

.input-error {
  color: red;
}

.user-icon {
  width: 45px;
  height: 45px;
}

.container.containerLayout {
  max-width: 100% !important;
}

input#walletAddress {
  padding-right: 40px;
}

.view_setting_password {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
}

.mirrorfly-root .container .recent-chatlist .recent-chatlist-header .menu-dropdown li:nth-child(4) {
  display: none !important;
}

.typeWriter {
  display: inline-flex;
}

.typeWriter span {
  word-break: break-all;
  height: 1.2em;
  width: 0%;
  overflow: hidden;
  animation: t 1s linear infinite alternate;
}

.typeWriter span:before {
  content: " ";
  display: inline-block;
}

@keyframes t {

  90%,
  100% {
    width: 100%;
  }
}

.custom-select {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: #fff !important;
  color: #000 !important;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  padding-right: 32px !important;
}
.customSelect {
  color: #1c1c1c;
  background: #80808038;
  border: 1px solid #80808038;
  border-radius: 7px;
  padding: 5px;
  width: 100%;
}

.livenessBtn {
  font-size: 16px !important;
}

@media (min-width: 1685.98px) {

  body .nftCard .tranding-img,
  body .fredCard .tranding-img {
    height: 400px;
  }
}

@media (max-width: 1199.98px) {

  .user-form input,
  .form-control {
    height: 42px;
  }

  .noDataPage {
    height: 60px !important;
  }

  .noDataPage h6 {
    font-size: 14px;
  }

  .tvtDetail .sideImage .imageFigure {
    height: 40vh;
  }

  .center-height {
    min-height: auto;
    margin-bottom: 50px;
  }

  .marketplace-tabs .nav-link {
    width: 130px;
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  .transaction-navs .nav-link {
    width: 90px !important;
  }

  .livenessBtn {
    font-size: 10px !important;
  }

  .receive-tkn {
    top: 3px;
  }

  .user-icon {
    width: 30px;
    height: 30px;
  }

  .left-sidebar {
    padding-top: 24px;
  }

  .nav-left span.align-middle.cursor-pointer {
    display: none;
  }

  .dashboard-left {
    width: 50px;
  }

  .dash-pages li a:not(:last-child) {
    margin-bottom: 10px;
  }

  .dash-pages li a {
    padding: 10px;
    text-align: center;
  }

  .dash-pages li a span {
    display: none;
  }

  .dash-pages li a i {
    margin: 0 !important;
  }

  .dashboard-right {
    width: calc(100% - 50px);
  }

  .dash-logo {
    padding: 0 0 20px;
  }

  .small-icon,
  .big-logo {
    display: none;
  }

  .small-icon {
    display: block;
    width: 30px;
    margin: auto;
  }

  .fs-20 {
    font-size: 14px;
    line-height: 22px;
  }

  .nav-right .notification-icon {
    width: 30px;
    padding: 4px;
  }

  .faq-wrapper {
    padding: 50px 0;
  }

  .fs-37 {
    font-size: 24px;
    line-height: 32px;
  }
}

.card-cols {
  padding: 0px 10px;
}

.tab_container {
  max-width: 480px;
  margin: 0 auto;
}

.tab-panel ul li button {
  padding: 7px 19px;
  border-radius: 40px !important;
  color: #000 !important;
  margin: 0 5px;
  border: 1px solid transparent;
}

.tab-panel ul li .nav-link.active,
.tab-panel ul li button:active,
.tab-panel ul li button:focus {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb !important;
}

.swap-sell {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 10px 20px;
  border: 1px solid #ebebeb;
  margin: 6px 0;
}

.sell-col1 {
  width: 70%;
  text-align: start;
  padding-right: 20px;
}

.sell-col2 {
  width: 30%;
  text-align: center;
}

.swap-sell label,
.swap-sell span {
  color: #666;
}

.swap-sell input,
.swap-sell input:focus,
.swap-sell input:active {
  font-size: 36px;
  font-weight: 500;
  max-height: 44px;
  background-color: transparent;
  border: 0px;
  padding: 0px;
  box-shadow: none;
  margin: 5px 0;
}

.swap-sell button {
  width: 110px;
  background-color: #fff;
  border-radius: 60px;
  border: 1px solid #eaeaea !important;
  padding: 5px 8px;
  box-shadow: 0px 0px 7px 2px #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.swap-sell button:hover {
  background-color: rgb(218, 218, 218);
}

.swap-sell button img {
  width: 28px;
  padding-right: 5px;
}

.swap-sell button i {
  padding-left: 5px;
}

.tab-panel .swap {
  position: relative;
}

.tab-panel .swap {
  position: relative;
}

.swap .convert-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  border: 5px solid #ffffff;
  padding: 3px 5px;
  border-radius: 14px;
}

.swap .convert-btn:hover,
.swap .convert-btn:focus,
.swap .convert-btn:active {
  background-color: #f0f0f0;
  border: 5px solid #ffffff;
}

.swap .convert-btn img {
  width: 21px;
}

.tab-content .transfer {
  background-color: #f9f9f9;
  text-align: left;
  border-radius: 15px;
  padding: 10px 20px;
  border: 1px solid #ebebeb;
}

.tab-content .transfer input {
  background-color: transparent;
  border: 0px;
  box-shadow: none !important;
  padding-left: 0px;
}

.connect-wallet-btn {
  background-color: #fff;
  color: #2938ec !important;
  font-size: 20px;
  font-weight: 500;
  border-radius: 15px;
  border-color: #2938ec !important;
}

.connect-wallet-btn:hover,
.connect-wallet-btn:active,
.connect-wallet-btn:focus {
  background-color: #dddef5 !important;
}

.tokensearch input {
  box-shadow: none !important;
  border-color: #d4d4d4 !important;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.tokenitems {
  padding: 0 10px;
}

.tokenitems .token-image {
  width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 50%;
}

.tokenitems .token-image img {
  width: 42px;
}

.popular-tokens .tokenitems {
  display: flex;
  align-items: center;
}

.tokenitems .token-name {
  padding: 10px 14px;
  flex-grow: 1;
  text-align: left;
}

.tokenitems:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.modal-dialog .modal-content {
  border-radius: 15px;
}

.swap-buy {
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 10px 20px;
  border: 1px solid #ebebeb;
}

.swap-buy .enter-amount input {
  font-size: 50px;
  font-weight: 500;
  background-color: transparent;
  border: 0px;
  box-shadow: none;
  text-align: center;
}

.btn-modal img {
  width: 27px;
  padding-right: 5px;
}

.btn-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  margin: 0 auto;
  border: 0px;
}

.btn-modal i {
  padding-left: 5px;
}

.light-btn {
  background-color: #fff;
  border: 1px solid #ebebeb;
  margin: 0 5px;
  border-radius: 15px;
}

.light-btn:hover,
.light-btn:active,
.light-btn:focus {
  background-color: #d8d8d8 !important;
  border: 1px solid #dadada !important;
}

.modal-header .btn-close {
  box-shadow: none;
}