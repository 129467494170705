.noDataPage {
  font-size: 14px !important;
  /* border: 1px solid; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #fff;
  display: flex;
  justify-content: center;
  height: 120px;
  align-items: center;
  /* padding: 100px 0px; */
  border-radius: 10px;
}
