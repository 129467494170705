.solos-card {
  /* box-shadow: 1.4718068838119507px 2.2077105045318604px 7.359034538269043px 0px
    #00000026; */
  background: url(../../images/usdc_card_bg.png) no-repeat center center;
  background-size: cover;
  border-radius: 9px;
  box-shadow: 0px 35px 20px -20px rgba(0, 0, 0, 0.45);
  padding: 20px;
  min-height: 220px;
}

.solos-card .token-btn {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background-color: #fff;
}
.solos-card .fs-8 {
  font-size: 14px;
}

@media (max-width: 1399.98px) {
  .red-token,
  .solos-card,
  .booq-card {
    min-height: 240px !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 767.98px) {
  .token-btn {
    width: 30px !important;
    height: 30px !important;
  }
  .token-bottom button {
    font-size: 10px !important;
  }
  .solos-card .fs-8 {
    font-size: 10px !important;
  }
}
