.fierce-card {
  /* box-shadow: 1.4718068838119507px 2.2077105045318604px 7.359034538269043px 0px
    #00000026; */
  background: url(../../images/blackCard.png) no-repeat center center;
  background-color: #7600EC;
  background-size: cover;
  border-radius: 9px;
  padding: 20px;
  box-shadow: 0px 35px 20px -20px rgba(0, 0, 0, 0.45);
  min-height: 220px;
  color: #fff;
}
.terms-modal .modal-content{
  border-radius: 20px;
}
.terms-modal .info-box{
  padding: 10px;
  border-bottom: 1px solid #DFDFDF;
}
.info-box .info-username{
  color: #1E9F14;
}
.large-checkbox {
  width: 18px;
  height: 18px;
}
.terms-modal .header-bg{
  background: #1E9F14;
  color: #fff;
  border-radius: 20px 20px 0px 0px;
}
.btn-outline-white {
  border: 1px solid #fff !important;
  background-color: none;
  color: #fff !important
}
.btn-outline-green {
  border: 1px solid #1E9F14 !important;
  background-color: none;
  color: #1E9F14 !important
}
.btn-green {
  border: 1px solid #1E9F14 !important;
  background-color: #1E9F14 !important;
  color: #fff !important;
}

.token-btn {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-color: #fff;
}

.solos-card .fs-8 {
  font-size: 14px;
}

.modal-font {
  font-size: 12px;
}

.font-card {
  font-size: 11px !important;
}