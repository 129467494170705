/* .tvtDetail{
    color: #000;
} */
.tvtDetail .sideImage .imageFigure {
  height: 350px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff !important ;
  /* background: url(https://solo-s3-bucket.s3.amazonaws.com/kbo55zwds0about_banner_1.png); */
}
.tvtDetail .sideImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #cce7ff;
  border-radius: 8px;
}
.tvtDetail .mintNowText {
  border: 1px solid gray;
  border-radius: 12px;
}
.tvtDetail .mintButton button {
  color: #fff;
  background-color: #0052ff;
  border-radius: 8px;
}
