.home-banner {
  min-height: 100vh;
  padding: 250px 0 0;
  background: url(../../../images/home-banner.png) no-repeat;
  background-position: right bottom;
}

.home-banner h1 {
  /* font-family: Inter; */
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 80px;
  font-weight: 500;
  line-height: 96px;
  letter-spacing: 0px;
  text-align: left;
  color: #0e1b3c;
}

.home-banner h1 span {
  color: #185ce3;
}

.home-banner p {
  /* font-family: Inter; */
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e1b3c;
  max-width: 45%;
}

@media (max-width: 991.98px) {
  .home-banner {
    background: none;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .home-banner h1 {
    font-size: 50px;
    line-height: 66px;
  }
  .home-banner p {
    font-size: 20px;
    line-height: 30px;
    max-width: 60%;
  }
}

@media (max-width: 767.98px) {
  .home-banner h1 {
    font-size: 40px;
    line-height: 56px;
  }
  .home-banner p {
    font-size: 16px;
    line-height: 28px;
    max-width: 100%;
  }
}

@media (max-width: 576.98px) {
  .home-banner h1 {
    font-size: 30px;
    line-height: 46px;
  }
}
